body {
  color: #333;
}

.video {
  width: 100%;
  height: 400px;
  margin-bottom: 15px;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 50px;
  width: 400px;
  height: 100vh;
  /* background-color: #005fdc; */
  background-color: rgba(0, 116, 160, 0.6);
  color: white;
  padding: 50px 20px;
}

.paypal-img {
  max-width: 250px;
  height: auto;
}

.poppi-img {
  max-width: 40px;
  height: auto;
}

.mow-img {
  width: 100%;
  max-width: 500px;
  height: auto;
}

.quote {
  font-family: "Nanum Pen Script", cursive;
  font-size: 32px;
}

.quote-author {
  display: block;
  margin-top: 15px;
  font-size: 18px;
}

.bg-primary {
  background-color: #005fdc !important;
}

.text-primary {
  color: #005fdc !important;
}

.btn-primary {
  background-color: #005fdc !important;
  color: white;
  border: 1px solid #005fdc;
  font-weight: 700 !important;
}

.btn-primary:hover {
  background-color: white !important;
  color: #005fdc;
  border: 1px solid #005fdc;
}

.btn-outline-primary {
  color: 005fdc;
  border: 1px solid #005fdc;
  font-weight: 700 !important;
}

.btn-outline-primary:hover {
  background-color: #005fdc !important;
  color: white;
  border: 1px solid #005fdc;
}

.logo-text {
  font-family: "Nanum Pen Script", cursive;
  font-size: 64px;
  padding: 0px;
  margin-top: -25px;
}

.form-full {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #333;
  padding: 0px 20px;
}

.logo-text-full {
  font-family: "Nanum Pen Script", cursive;
  font-size: 64px;
  padding: 0px;
  margin-top: -25px;
}

.react-tel-input .form-control {
  width: 100% !important;
}

@media only screen and (max-width: 1200px) {
  .form {
    display: none;
  }

  .logo-text-full {
    font-size: 44px;
  }
}

.intl-tel-input {
  display: block !important;
}
